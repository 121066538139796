import { Injectable } from '@angular/core'
import { WebApiService, Cache } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IRequest, IRequestResult, IRequestList, IReqTest, ISample, IRequestListResult, ILabPropDetail, IFile, IExtUser, IMSUser, ITranslation } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class MicrosoftService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async loginMicrosoft(): Promise<void>
    public async loginMicrosoft(state: string): Promise<void>
    public async loginMicrosoft(state?: string): Promise<void> {
        if (typeof state === 'undefined') state = ''
        window.location.replace('/api/microsoft/loginmicrosoft?state=' + state)
        return
    }

    public async getUsername(): Promise<string>
    public async getUsername(state: string): Promise<string>
    public async getUsername(state?: string): Promise<string> {
        if (typeof state === 'undefined') state = ''
        return new Promise<string>((resolve, reject) => {
            this._webapi.get<string>('microsoft', 'GetSUsername', { state }).then((vl) => {
                if (vl && vl != '')
                    resolve(vl)
                else
                    resolve('')
            }).catch(e => reject(e))
        })
    }
    public async getUser(): Promise<IExtUser>
    public async getUser(state: string)
    public async getUser(state?: string): Promise<IExtUser> {
        if (typeof state === 'undefined') state = ''
        let vl = await this._webapi.get<IMSUser>('microsoft', 'GetUser', { state })
        return { type: "Microsoft", email: vl.email, id: vl.id, name: vl.name }
    }


    public async getUserWithToken(token: string): Promise<IExtUser> {
        let vl = await this._webapi.get<IMSUser>('microsoft', 'GetUserWithToken', { token })
        return { type: "Microsoft", email: vl.email, id: vl.id, name: vl.name }

    }

    public async getUsernameWithToken(token: string): Promise<string> {
        try {
            let vl = await this._webapi.get<string>('microsoft', 'GetUsernameWithToken', { token })
            return vl
        } catch(e) {
            return null;
        }
    }

    public async getTokenWithState(state: string): Promise<string> {
        return await this._webapi.get<string>('microsoft', 'getStateToken', { state })
    }

    public async loginMicrosoftToken(): Promise<void> {
        let state = await this._webapi.get<string>('microsoft', 'getState', {})
        this._storage.local.set('state', state);
        if (state != '') {
            window.location.replace('/api/microsoft/loginmicrosoft?state=' + state)
            return
        }
    }

    public async testMail(): Promise<string> {
        return await this._webapi.get<string>('microsoft', 'TestEmail', {})
    }

    public async testMailKvkk(): Promise<string> {
        return await this._webapi.get<string>('microsoft', 'TestEmailKvkk', {})
    }

    public async testSharepoint(): Promise<string> {
        return await this._webapi.get<string>('microsoft', 'TestSharepoint', {})
    }
    
    public async getApiKey(): Promise<string> {
        return await this._webapi.get<string>('microsoft', 'ApiKey', {})
    }
    public async updateSharepointIDs(host: string, path: string, folder: string): Promise < { siteId: string, driveId: string, itemId: string, items: { id: string, name: string }[] }> {
        return await this._webapi.get<{siteId: string, driveId: string, itemId: string, items: { id: string, name: string }[]}>('microsoft', 'updateSharepointIDs', { host, path, folder })
    }

    //private backup: { [key: string]: string | Promise<string> } = {}

    public async translate(text: string, language: string): Promise<ITranslation> {
        return { language: language, translated: false, translation: text }
        return Cache('Translate', (_text: string, _language: string) => {
            return this._webapi.post<ITranslation>('microsoft', 'translate', { text: text }, { language }, false)
        }, 60).get(text, language)
        
    }
    public async requestSendMailReminder(): Promise<boolean> {
        return await this._webapi.get<boolean>('automate', 'RequestSendMailReminder', {})
    }
}
