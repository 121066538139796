import { BrowserModule } from '@angular/platform-browser'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { StorageServiceModule } from 'ngx-webstorage-service'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AppComponent } from './app.component'
//import { DeviceDetectorService } from 'ngx-device-detector'
//import { registerLocaleData } from '@angular/common';
//import localeTr from '@angular/common/locales/tr';

//import { AuthModule, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { MAIN_ROUTES } from './routing'
import { SharedModule } from '@shared/shared.module';


//registerLocaleData(localeTr, 'tr');



//const oidc_configuration = 'assets/auth.clientConfiguration.json';
// if your config is on server side
// const oidc_configuration = ${window.location.origin}/api/ClientAppSettings

//export function loadConfig(oidcConfigService: OidcConfigService) {
//    return () => oidcConfigService.load(oidc_configuration);
//}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        StorageServiceModule,
        NgbModule,
        RouterModule.forRoot(MAIN_ROUTES, { useHash: true, onSameUrlNavigation: 'reload' }),
        //AuthModule.forRoot()
    ],
    providers: [
        //DeviceDetectorService
        //OidcConfigService,
        //{
        //    provide: APP_INITIALIZER,
        //    useFactory: loadConfig,
        //    deps: [OidcConfigService],
        //    multi: true,
        //},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    //constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {
    //    this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {

    //        // Use the configResult to set the configurations

    //        const config: OpenIdConfiguration = {
    //            stsServer: configResult.customConfig.stsServer,
    //            redirect_url: 'https://localhost:4200',
    //            client_id: 'angularClient',
    //            scope: 'openid profile email',
    //            response_type: 'code',
    //            silent_renew: true,
    //            silent_renew_url: 'https://localhost:4200/silent-renew.html',
    //            log_console_debug_active: true,
    //            // all other properties you want to set
    //        };

    //        this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
    //    });
    //}

}
