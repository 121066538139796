import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs'
import { IMessageResult, IFile } from '../models'

declare var window: any;

const waitUntil: (call: () => 'RESOLVE' | 'REJECT' | 'WAIT') => Promise < void> = (call) => {
    let interval: any
    return new Promise<void>((resolve, reject) => {
        interval = setInterval(() => {
            let rs = call()
            if (rs === 'RESOLVE') {
                clearInterval(interval)
                resolve()
            } else if (rs === 'REJECT') {
                clearInterval(interval)
                reject()
            }
        }, 500)
    })
}

class CacheController<T extends any[], R = any> {
    private _cacheTimeout: number
    private _status: { [args: string]: 'NOT LOADED' | 'LOADING' | 'LOADED' | 'ERROR' }
    private _lastRefresh: { [args: string]: number }
    private _data: { [args: string]: R }
    private _lastError: { [args: string]: any }
    private _loader: (...args: T) => Promise<R>

    constructor(loader: (...args: T) => Promise<R>, timeout?: number) {
        this._loader = loader
        this._cacheTimeout = timeout ?? 90 * 1000
        this._lastRefresh = {}
        this._lastError = {}
        this._data = {}
        this._status = {}
    }

    public async get(...args: T): Promise<R> {
        let argsKey: string = JSON.stringify(args)
        if (this._status[argsKey] === undefined) this._status[argsKey] = 'NOT LOADED'

        if (this._status[argsKey] === 'LOADING') {
            //Wait
            try {
                await waitUntil(() => {
                    if (this._status[argsKey] === 'LOADED') return 'RESOLVE'
                    if (this._status[argsKey] === 'ERROR') return 'REJECT'
                    return 'WAIT'
                })
            } catch {
                throw this._lastError[argsKey]
            }
            let res = this._data[argsKey]
            if (res !== undefined) return res
            throw new Error('Undefined in cache')
        } else {
            let last: number = this._lastRefresh[argsKey] ?? 0
            let has: boolean = this._data[argsKey] !== undefined
            let current: number = +new Date()
            let timePast: number = current - last

            if (last === 0 || timePast >= this._cacheTimeout || !has) {
                try {
                    this._status[argsKey] = 'LOADING'
                    this._data[argsKey] = await this._loader(...args)
                    this._lastRefresh[argsKey] = +new Date()
                    this._lastError[argsKey] = undefined
                    this._status[argsKey] = 'LOADED'
                } catch (e) {
                    this._lastError[argsKey] = e
                    this._status[argsKey] = 'ERROR'
                }
            }
            if (this._status[argsKey] === 'ERROR') throw this._lastError[argsKey]
            let res = this._data[argsKey]
            if (res !== undefined) return res
            throw new Error('Undefined in cache')
        }
    }

    public clean() {
        this._data = {}
        this._lastRefresh = {}
        this._lastError = {}
        this._status = {}
    }

    public force(...args: T): Promise<R> {
        let argsKey: string = JSON.stringify(args)
        delete this._data[argsKey]
        delete this._lastError[argsKey]
        delete this._lastRefresh[argsKey]
        delete this._status[argsKey]
        return this.get(...args)
    }
}

export const Cache = <T extends any[], R = any>(key: string, loader: (...args: T) => Promise<R>, timeout?: number): CacheController<T, R> => {
    if (window.__CACHES__ === undefined) window.__CACHES__ = {}
    if (!window.__CACHES__[key]) {
        window.__CACHES__[key] = new CacheController(loader, (timeout ?? 90) * 1000)
    }
    return window.__CACHES__[key] as CacheController<T, R>
}

@Injectable({
    providedIn: 'root'
})
export class WebApiService {
    public transUserID: string = ''
    private _workingChanged$ = new BehaviorSubject<boolean>(false);
    private _headers(): HttpHeaders { return new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.transUserID }) }

    public workingChanged$ = this._workingChanged$.asObservable()

    constructor(private _http: HttpClient) { }

    private _currentIndex: number = 0
    private _processes: number[] = []

    private start(): number {
        let nm: number = this._currentIndex
        this._currentIndex++
        this._processes.push(nm)
        setTimeout(() => {
            this._doWorking()
        }, 100)
        return nm
    }

    private finish(value: number) {
        let ind = this._processes.indexOf(value)
        if (ind != -1) {
            this._processes.splice(ind, 1)
            setTimeout(() => {
                this._doWorking()
            }, 100)
        }
    }

    private _lastWorking: boolean = false
    private _workingStarted: boolean = false

    public get isWorking(): boolean {
        return this._lastWorking
    }

    private _doWorking() {
        if (this._workingStarted) return
        this._workingStarted = true
        if (this._processes.length == 0) {
            if (this._lastWorking == true) {
                this._lastWorking = false
                this._workingChanged$.next(false)
            }
        } else {
            if (this._lastWorking == false) {
                this._lastWorking = true
                this._workingChanged$.next(true)
            }
            setTimeout(() => {
                this._doWorking()
            }, 100)
        }
        this._workingStarted = false
    }





    public get<T extends any>(controller: string, action: string, data: any, trackWorking: boolean = true): Promise<T> {
        console.log("GET: " + controller + "." + action)

        //let key: string = controller + '.' + action
        //return Cache(key, (vl: any) => {
            let vl: any = data
            let trackIndex: number = (trackWorking) ? this.start() : -1;
            return new Promise<T>((resolve, reject) => {
                let params = new URLSearchParams()
                for (let o in vl) {
                    params.set(o, vl[o])
                }
                this._http.get<IMessageResult<T>>('api/' + controller + '/' + action, { headers: this._headers(), params: vl })
                    .subscribe((value) => {
                        this.finish(trackIndex)
                        let result: IMessageResult<T> = value || { hasError: false, message: '', data: null }
                        if (result.hasError) {
                            reject(new Error(result.message))
                        } else {
                            resolve(result.data)
                        }
                    }, (error) => {
                        this.finish(trackIndex)
                        reject(error)
                    })
            })
    //    }, cacheTimeout * 1000).get(data)
    }

    public async post<T>(controller: string, action: string, data: any, params: any = {}, trackWorking: boolean = true): Promise<T> {
        if (controller !== 'microsoft' || action !== 'translate') console.log("POST: " + controller + "." + action)

        //let key: string = controller + '.' + action// + '(' + JSON.stringify(data) + ')'
        //return Cache(key, (vl: any, pr: any) => {
        let vl: any = data
        let pr: any = params
            let trackIndex: number = (trackWorking) ? this.start() : -1;
            return new Promise<T>((resolve, reject) => {
                this._http.post<IMessageResult<T>>('api/' + controller + '/' + action, vl, { headers: this._headers(), params: pr })
                    .subscribe((value) => {
                        this.finish(trackIndex)
                        let result: IMessageResult<T> = value || { hasError: false, message: '', data: null }

                        if (result.hasError) {
                            reject(new Error(result.message))
                        } else {
                            resolve(result.data)
                        }
                    }, (error) => {
                        this.finish(trackIndex)
                        reject(error);
                    })
            })
    //    }, cacheTimeout * 1000).get(data, params)
    }

    public async fileInfo(fileID: string, isTemporary: boolean = false): Promise<IFile> {
        return await Cache('fileInfo', (_fileId: string, _isTemporary: boolean) => {
            return this.get<IFile>('file', ((_isTemporary) ? 'infotemp' : 'info'), { fileId: _fileId })
        }).get(fileID, isTemporary)
    }

    public async download(fileID: string, isTemporary: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let params = new URLSearchParams()
            params.append('FileID', fileID)

            window.location.replace('api/file/' + ((isTemporary) ? 'downloadtemp' : 'download') + '?' + params.toString());
        })
    }

    public async upload(files: File[], isTemporary: boolean = false, progress: (value: { hasError: boolean, progress: number, length: number }) => void = (e) => { }): Promise<IFile[]> {
        let path: string = 'api/file/' + ((isTemporary) ? 'uploadtemp' : 'upload')
        return new Promise<IFile[]>((resolve, reject) => {
            let formData: FormData = new FormData()
            let xhr: XMLHttpRequest = new XMLHttpRequest()

            for (let fileIndex: number = 0; fileIndex < files.length; fileIndex++) {
                formData.append('file', files[fileIndex], files[fileIndex].name)
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let result: IMessageResult<IFile[]> = JSON.parse(xhr.response)
                        if (!result.hasError) {
                            //if (typeof progress !== 'undefined') progress(100)
                            resolve(result.data)
                        } else {
                            reject(result.message)
                        }
                    } else {
                        reject(xhr.response)
                    }
                }
            }

            xhr.onabort = () => {
                progress({ hasError: true, progress: 100, length: 0 })
            }

            xhr.onerror = () => {
                progress({ hasError: true, progress: 100, length: 0 })
            }
            
            xhr.upload.onprogress = (event) => {
                progress({ hasError: false, progress: (event.loaded / event.total) * 100, length: event.total })
                //if (typeof progress !== 'undefined') progress(Math.round(event.loaded / event.total * 100))
            }

            xhr.upload.onload = () => {
                progress({ hasError: false, progress: 100, length: 0 })
            }

            xhr.open('POST', path, true)
            xhr.send(formData)
        })
    }

}