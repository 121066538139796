import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'

import { UserService, StorageService } from '@shared/services'
import { PublicService } from './public.service'

@Injectable({
    providedIn: 'root'
})
export class AuthCanActivateGuard implements CanActivate {

    constructor(private _router: Router, private _user: UserService, private _store: StorageService, public pbl: PublicService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let isAuth = await this._user.isAuthenticated

        if (isAuth) {
            if (this.pbl.roleID.indexOf('DZN:') != -1) {
                let url: string = ''
                for (let p of route.pathFromRoot) {
                    for (let x of p.url.map(i => i.path)) {
                        url += '/' + x
                    }
                }

                if (url.indexOf('/lab/excelImport') == -1) {
                    this._router.navigateByUrl('/lab/excelImport')
                    return false
                }
            }
            if (this.pbl.roleID.indexOf('ATLAS:') != -1) {
                let url: string = ''
                for (let p of route.pathFromRoot) {
                    for (let x of p.url.map(i => i.path)) {
                        url += '/' + x
                    }
                }
                if (url.indexOf('/lab/apportionments') == -1 && url.indexOf('/lab/siteCoordinatorInvoice') == -1) {
                    this._router.navigateByUrl('/lab/apportionments')
                    return false
                }
            }
            return true
        } else {
            // find url
            let url: string[] = []
            for (let p of route.pathFromRoot) {
                url.push(...p.url.map(i => i.path))
            }

            this._store.session.set('url', url)

            this._router.navigate(['authenticate'])
            return false
        }
    }

}
