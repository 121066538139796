import { Injectable, EventEmitter } from '@angular/core'
import { Cache, WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'

import { IUser, IActUser, IKeyValue, ILogin } from '../models'
//import * as msal from 'msal'
import { Router, UrlSerializer } from '@angular/router';
import { MicrosoftService } from './microsoft.service';





@Injectable({
    providedIn: 'root'
})
export class UserService {


    public $activeUserChange: EventEmitter<any> = new EventEmitter<any>();
    //public $redirectCallback: EventEmitter<any> = new EventEmitter<any>()

    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService, private _router: Router, private _ms: MicrosoftService) { }

    public async logout(): Promise<void> {
        this._loginDetails = undefined
        this._activeUser = undefined
        this._storage.local.remove('state')
        this._storage.local.remove('token')
        this._router.navigateByUrl('/authenticate')
    }


    public async login(): Promise<void> {
        await this._ms.loginMicrosoftToken()
    }

    private setCookie = <T>(key: string, value: T, expireMs: number) => {
        let d = new Date();
        d.setTime(d.getTime() + (expireMs * 1000));
        let expires = 'expires=' + d.toUTCString();
        document.cookie = key + '=' + JSON.stringify(value) + ";" + expires + ";path=/";
    }

    private getCookie = <T>(key: string, defaultValue: T): T => {
        let value: T = defaultValue

        let name: string = key + '='
        let ca: string[] = document.cookie.split(';')
        for (let i: number = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                value = JSON.parse(c.substring(name.length, c.length)) as T
            }
        }
        return value
    }


    public async setLoginDetails(userId: string): Promise<boolean> {
        try {
            let usr = await this._webapi.get<ILogin>('user', 'userdetails', { userID: userId });
            this._loginDetails = usr
            if (!usr.roles || usr.roles.length == 0) {
                return false
            }
            if (!this._activeUser || this._activeUser.value.userID != usr.user.userID || this._activeUser.value.roleID != usr.roles[0].roleID) {
                await this.actAs(usr.user.userID, usr.roles[0].roleID)
                this.$activeUserChange.emit();
            }

            return true
        } catch {
            return false
        }
    }


    public async tryAuthenticate(): Promise<boolean> {
        if (this._storage.local.has('token')) {
            // daha once giris yapilmis
            let token: string = this._storage.local.get('token')
            if (token == null || token == '') {
                this._storage.local.remove('state')
                this._storage.local.remove('token')
            } else {
                this._storage.local.remove('state')
                this._storage.local.set('token', token)
                let vl: string = await this._ms.getUsernameWithToken(token)
                if (vl == null) {
                    this._storage.local.remove('state')
                    this._storage.local.remove('token')
                } else {
                    let usr = await this._webapi.get<ILogin>('user', 'updateUserdetails', { token });
                    let res = await this.setLoginDetails(vl)
                    if (res == false) {
                        this._storage.local.remove('state')
                        this._storage.local.remove('token')
                        return false
                    } else {
                        this._webapi.transUserID = vl
                        return true
                    }
                }

            }
        } else {
            // daha once giris yapilmamis
            if (this._storage.local.has('state')) {
                // giris yapilmaya calisiliyor
                let state: string = this._storage.local.get('state')
                let token: string = await this._ms.getTokenWithState(state)
                if (token == null || token == '') {
                    this._storage.local.remove('state')
                    this._storage.local.remove('token')
                } else {
                    this._storage.local.remove('state')
                    this._storage.local.set('token', token)
                    let vl = await this._ms.getUsernameWithToken(token)
                    if (vl == null) {
                        this._storage.local.remove('state')
                        this._storage.local.remove('token')
                    } else {
                        let usr = await this._webapi.get<ILogin>('user', 'updateUserdetails', { token });
                        let res = await this.setLoginDetails(vl)
                        if (res == false) {
                            this._storage.local.remove('state')
                            this._storage.local.remove('token')
                            return false
                        } else {
                            this._webapi.transUserID = vl
                            return true
                        }
                    }
                }
            } else {
                return false
            }
        }

    }

    private _loginDetails: ILogin
    private _activeUser: {
        value: IActUser, keys: { [key: string]: IKeyValue }
    }

    public async actAs(userID: string, roleID: string): Promise<void> {
        for (let o of this._loginDetails.users) {
            if (o.userID == userID && o.roleID == roleID) {
                let keys = await this._webapi.get<{ [key: string]: IKeyValue }>('user', 'userkeys', { userID, roleID })
                this._activeUser = { value: o, keys }
                this.$activeUserChange.emit();
            }
        }
    }

    public get loginDetails(): ILogin {
        return this._loginDetails
    }

    public get activeUser(): IActUser {
        if (this._activeUser)
            return this._activeUser.value
        return null
    }

    private access(key: string): IKeyValue {
        if (typeof this._activeUser !== 'undefined') {
            if (key in this._activeUser.keys) {
                return this._activeUser.keys[key]
            } else {
                throw 'the key (' + key + ') not found'
            }
        } else {
            return null
        }
    }

    public stringValue(key: string): string {
        let vl = this.access(key)
        if (vl == null) return null
        if (vl.type != 'string') {
            throw 'the key (' + key + ') is not string value'
        } else {
            return vl.stringValue
        }

    }

    public numberValue(key: string): number {
        let vl = this.access(key)
        if (vl == null) return null
        if (vl.type != 'number') {
            throw 'the key (' + key + ') is not number value'
        } else {
            return vl.numberValue
        }

    }

    public arrayValue(key: string): string[] {
        let vl = this.access(key)
        if (vl == null) return null
        if (vl.type != 'array') {
            throw 'the key (' + key + ') is not array value'
        } else {
            return vl.arrayValue
        }

    }

    public booleanValue(key: string): boolean {
        let vl = this.access(key)
        if (vl == null) return null
        if (vl.type != 'boolean') {
            throw 'the key (' + key + ') is not boolean value'
        } else {
            return vl.boolValue
        }
    }


    public get availableUsers(): IActUser[] {
        if (this.booleanValue('ChangeUser') == true) {
            return this.loginDetails.users
        } else {
            if (this.activeUser)
                return [this.activeUser]
            return []
        }
    }

    public get isAuthenticated(): boolean {
        if (typeof this._loginDetails === 'undefined') {
            return false;
        } else {
            return true;
        }
    }

    public async saveBackupUser(userID: string, backupUserID: string): Promise<boolean> {
        return await this._webapi.get<boolean>('user', 'SaveBackupUser', { userID, backupUserID })
    }

    public async searchUser(text: string): Promise<IUser[]> {
        return await this._webapi.get<IUser[]>('user', 'SearchUser', { text })
    }

    public async getUsers(): Promise<IUser[]> {
        return Cache('GetUsers', () => {
            return this._webapi.get<IUser[]>('user', 'GetUsers', {})
        }, 120).get()
    }
    public async getActUsers(uID: string): Promise<IActUser[]> {
        return this._webapi.get<IActUser[]>('user', 'getActUsers', { uID })
    }

    public async findBackupUserID(backupUserID: string): Promise<string> {
        return Cache('FindBackupUser', (_backupUserId) => {
            return this._webapi.get<string>('user', 'FindBackupUserID', { backupUserId: _backupUserId })
        }, 120).get(backupUserID)
        
    }

}

