
export interface IUser {
    userID: string
    firstname: string
    lastname: string
    title: string
}

export const IUser_Empty: IUser = { firstname: '', lastname: '', title: '', userID: '' }


export interface IKeyValue {
    type: 'number' | 'string' | 'array' | 'boolean'
    stringValue: string
    boolValue: boolean
    numberValue: number
    arrayValue: string[]
}

export interface IRole {
    roleID: string
    groupText: string
    text: string
}

export const IRole_Empty: IRole = {roleID: '', groupText: '', text: ''}

export interface IActUser extends IUser, Omit<IRole, 'text'> {
    roleText: string
}

export const IActUser_Empty: IActUser = { ...IUser_Empty, roleID: '', roleText: '', groupText: '' }

export interface ILogin {
    canLogin: boolean;
    message: string;
    user: IUser;
    roles: IRole[];
    users: IActUser[];
}

export const ILogin_Empty: ILogin = { canLogin: false, message: '', user: IUser_Empty, roles: [IRole_Empty], users: [IActUser_Empty]}

