import { Routes } from '@angular/router'
import { AuthCanActivateGuard } from '@shared/services/auth-can-activate.guard'
import { AppComponent } from './app.component';

export const MAIN_ROUTES: Routes = [
    { path: 'main', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule), canActivate: [AuthCanActivateGuard] },
    { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
    { path: 'authenticate', loadChildren: () => import('./modules/authenticate/authenticate.module').then(m => m.AuthenticateModule) },
    { path: 'error', loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule) },
    { path: 'lab', loadChildren: () => import('./modules/lab/lab.module').then(m => m.LabModule) },
    { path: 'gene2info', loadChildren: () => import('./modules/gene2info/gene2info.module').then(m => m.Gene2InfoModule) },
    { path: 'list', loadChildren: () => import('./modules/list/list.module').then(m => m.ListModule) },
    { path: 'request', loadChildren: () => import('./modules/request/request.module').then(m => m.RequestModule) },
    { path: 'test', loadChildren: () => import('./modules/test/test.module').then(m => m.TestModule) },
    { path: 'marketReport', loadChildren: () => import('./modules/marketReport/marketReport.module').then(m => m.MarketReportModule) },
    { path: 'documents', loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentModule), canActivate: [AuthCanActivateGuard] },
    { path: '', redirectTo: '/authenticate', pathMatch: 'prefix' },
    { path: '*', redirectTo: '/error/404', pathMatch: 'prefix' }
]

