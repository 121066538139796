import { Injectable } from '@angular/core'
import { SettingsService } from './settings.service'
import { UserService } from './user.service'
import { Location } from '@angular/common';
import { IUser, IActUser, ITranslation } from '../models'
import { DeviceDetectorService } from 'ngx-device-detector'
import { WebApiService } from './webapi.service';
import { environment } from '../../../environments/environment';
import { getLanguage } from '../../../localizations';

@Injectable({
    providedIn: 'root'
})
export class PublicService {

    constructor(private _user: UserService, private _settings: SettingsService, private _device: DeviceDetectorService, private _location: Location, private _webapi: WebApiService) { }

    public get roleID(): string {
        return this._user.activeUser.roleID
    }

    public get userID(): string {

        return (this._user.activeUser == null) ? '' : this._user.activeUser.userID
    }

    public get isMobile(): boolean {
        return this._device.isMobile()
    }

    public get isDesktop(): boolean {
        return this._device.isDesktop()
    }

    public get isTablet(): boolean {
        return this._device.isTablet()
    }

    public goBack(): void {
        this._location.back()
    }

    public isLocal(): boolean {
        return (environment.production == false)
    }

    public async translate(text: string): Promise<string> {
        return new Promise<string>((res, rej) => {
            let language = getLanguage();
            this._webapi.post<ITranslation>('microsoft', 'translate', { text: text }, { language }, false)
                .then(a => {
                    res(a.translation)
                })
                .catch(e => {
                    rej(e)
                })

        })
    }

    public get canSeeBudgetSelectionBudgetDetail(): boolean {
        return this._user.booleanValue('canSeeBudgetSelectionBudgetDetail')
    }
}

