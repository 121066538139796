import { Injectable } from '@angular/core'
import { WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IContact, IAccount, IAccountList, IContactDetail, IKVKKCustomer, IConsentDetail, IAccountDetail, IConsentList, IGDPRForm, IGDPRFormList } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async getContact(contactID: string): Promise<IContact> {
        return await this._webapi.get<IContact>('list', 'GetContact', { contactID })
    }

    public async getAccount(accountID: string): Promise<IAccount> {
        return await this._webapi.get<IAccount>('list', 'GetAccount', { accountID })
    }

    public async searchAccount(text: string, uID: string): Promise<IAccount[]> {
        return await this._webapi.get<IAccount[]>('list', 'SearchAccount', { text, uID })
    }

    public async searchContact(text: string, uID: string): Promise<IContact[]> {
        return await this._webapi.get<IContact[]>('list', 'SearchContact', { text, uID })
    }

    public async getMyList(uID: string): Promise<IAccountList[]> {
        return await this._webapi.get<IAccountList[]>('list', 'GetMyList', { uID })
    }

    public async getContactDetails(contactID: string): Promise<IContactDetail> {
        return await this._webapi.get<IContactDetail>('list', 'GetContactDetails', { contactID })
    }

    public async getAccountDetails(accountID: string): Promise<IAccountDetail> {
        return await this._webapi.get<IAccountDetail>('list', 'GetAccountDetails', { accountID })
    }

    public async InviteKVKK(contactID: string, uID: string, email: string): Promise<boolean> {
        return await this._webapi.get<boolean>('list', 'InvileKVKK', { contactID, uID, email })
    }
    public async CheckKVKK(contactID: string): Promise<IKVKKCustomer> {
        return await this._webapi.get<IKVKKCustomer>('list', 'CheckKVKK', { contactID })
    }
    public async saveConsent(consent: IConsentDetail, contactID: string, accountID: string, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('list', 'SaveConsent', consent, { contactID, accountID, uID })
    }
    public async saveGDPRForm(form: IGDPRForm, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('list', 'SaveGDPRForm', form, { uID })
    }
    public async getConsentList(): Promise<IConsentList[]> {
        return await this._webapi.get<IConsentList[]>('list', 'GetConsentList', {})
    }
    public async getGDPRList(): Promise<IGDPRForm[]> {
        return await this._webapi.get<IGDPRForm[]>('list', 'GetGDPRList', {})
    }
}

