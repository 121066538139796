import { Injectable, Inject } from '@angular/core'

import { StorageService as sService, SESSION_STORAGE, LOCAL_STORAGE } from 'ngx-webstorage-service'

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(@Inject(SESSION_STORAGE) private _session: sService, @Inject(LOCAL_STORAGE) private _local: sService) { }

    public get session(): sService {
        return this._session
    }

    public get local(): sService {
        return this._local
    }
}

