import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RouterModule } from '@angular/router'
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



import { PageControl } from './controls/page/page.control'
import { MenuControl } from './controls/menu/menu.control'
import { DateControl } from './controls/date/date.control';
import { FileControl } from './controls/file/file.control';
import { ColumnHeaderControl } from '@shared/controls/colHeader/colHeader.control';
import { UpperCommentControl } from './controls/upper-comment/upper-comment.control';
import { UpperTextControl } from './controls/upper-text/upper-text.control';
import { dateDiff } from './pipes/datediff.pipe'
import { PageLinkControl, PageLinkContentDirective, PageLinkActionsDirective, PageLinkBreadCrumbDirective, PageLinkHeaderDirective, PageLinkFooterDirective } from './controls/page-link/page-link.control'
import { translateString } from './pipes/translate.pipe'
//import { AutoTranslatePipe } from './pipes/auto-translate.pipe'
import { AutoTranslateComponent } from './controls/auto-translate/auto-translate.control'
import { AutoCompleteControl } from './controls/autocomplete/autocomplete.control'
import { HcpSelectControl } from './controls/hcpselect/hcpselect.control'
import { OrgSelectControl } from './controls/orgselect/orgselect.control'
import { ThumbComponent } from './controls/thumb/thumb.control'
import { VendorSelectControl } from './controls/vendorSelect/vendorSelect.control'
import { UserSelectControl } from './controls/userSelect/userSelect.control'
import { SortHeaderControl } from './controls/sortHeader/sortHeader.control'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        PerfectScrollbarModule,
        FormsModule
    ],
    declarations: [
        PageControl,
        PageLinkControl,
        PageLinkActionsDirective,
        PageLinkBreadCrumbDirective,
        PageLinkContentDirective,
        PageLinkFooterDirective,
        PageLinkHeaderDirective,
        MenuControl,
        DateControl,
        FileControl,
        ColumnHeaderControl,
        UpperTextControl,
        UpperCommentControl,
        dateDiff,
        translateString,
        AutoTranslateComponent,
        AutoCompleteControl,
        HcpSelectControl,
        VendorSelectControl,
        UserSelectControl,
        OrgSelectControl,
        ThumbComponent,
        SortHeaderControl,
    ],
    exports: [
        PageControl,
        PageLinkControl,
        PageLinkContentDirective,
        PageLinkActionsDirective,
        PageLinkBreadCrumbDirective,
        PageLinkHeaderDirective,
        PageLinkFooterDirective,
        MenuControl,
        DateControl,
        FileControl,
        ColumnHeaderControl,
        UpperTextControl,
        UpperCommentControl,
        dateDiff,
        AutoCompleteControl,
        translateString,
        AutoTranslateComponent,
        CommonModule,
        FormsModule,
        NgbModule,
        PerfectScrollbarModule,
        HcpSelectControl,
        VendorSelectControl,
        UserSelectControl,
        OrgSelectControl,
        ThumbComponent,
        SortHeaderControl
    ],
    providers: [{
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }]
})
export class SharedModule { }