import { Injectable } from '@angular/core'
import { WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IRequest, IRequestResult, IRequestList, IReqTest, ISample, IRequestListResult, ILabPropDetail, IFile, IExcelLog, IList_Quotas, IContactTest, ILabMatchData_Result, ILabMatchData_Contact, ILabSampleData, IUser, IFieldCord_User, IFiledCord_ApportionmentListResult, IFieldCord_ApportionmentList, ILabMatchData_Account, ILabHelper_ResultMatchData } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class LabService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async addNewRequest(request: IRequest, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'addNewRequest', request, { uID })
    }
    public async changeRequestDetail(request: IRequest, isCancel: boolean, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'ChangeRequestDetail', request, { isCancel, uID })
    }
    public async readSharePointExcel(isFull: boolean): Promise<boolean> {
        return await this._webapi.get<boolean>('lab', 'ReadSharePointExcel', { isFull })
    }
    public async getSamples(): Promise<ISample[]> {
        return await this._webapi.get<ISample[]>('lab', 'getSamples', {})
    }
    public async getRequest(requestID: number, uID: string): Promise<IRequestResult> {
        return await this._webapi.get<IRequestResult>('lab', 'GetRequest', { requestID, uID })
    }

    public async getList(userID: string): Promise<IRequestListResult> {
        return await this._webapi.get<IRequestListResult>('lab', 'GetList', { userID })
    }

    public async getContactLastRequest(contactID: string): Promise<IRequest> {
        return await this._webapi.get<IRequest>('lab', 'GetContactLastRequest', { contactID })
    }

    public async importExcel(fileID: string, importType: number, uID: string): Promise<boolean> {
        return await this._webapi.get<boolean>('lab', 'ImportExcel', { fileID, importType, uID })
    }

    public async getFieldCities(): Promise<string[]> {
        return await this._webapi.get<string[]>('lab', 'GetFieldCities', {})
    }

    public async fillExcelPage(pageType: number): Promise<IExcelLog[]> {
        return await this._webapi.get<IExcelLog[]>('lab', 'FillExcelPage', { pageType })
    }

    public async getValidationResults(pageType: number, excelCode: string, date: string): Promise<string[]> {
        return await this._webapi.get<string[]>('lab', 'getValidationResults', { excelCode, date, pageType })
    }


    public async saveQuotas(param: IList_Quotas[], year: number, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'saveQuotas', param, { year, uID })
    }

    public async getTestsForQuota(isFull: boolean): Promise<ILabPropDetail[]> {
        return await this._webapi.get<ILabPropDetail[]>('lab', 'GetTestsForQuota', { isFull })
    }

    public async loadQuotaPage(year: number): Promise<IList_Quotas[]> {
        return await this._webapi.get<IList_Quotas[]>('lab', 'loadQuotaPage', { year })
    }

    public async getContactTests(contactID: string): Promise<IContactTest[]> {
        return await this._webapi.get<IContactTest[]>('lab', 'GetContactTests', { contactID })
    }

    public async getMatchData_Results(): Promise<ILabMatchData_Result[]> {
        return await this._webapi.get<ILabMatchData_Result[]>('lab', 'GetMatchData_Results', {})
    }

    public async saveResultMatchData(datas: ILabMatchData_Result[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'SaveResultMatchData', datas, { uID })
    }


    public async getMatchData_Contacts(): Promise<ILabMatchData_Contact[]> {
        return await this._webapi.get<ILabMatchData_Contact[]>('lab', 'getMatchData_Contacts', {})
    }

    public async getMatchData_Accounts(): Promise<ILabMatchData_Account[]> {
        return await this._webapi.get<ILabMatchData_Account[]>('lab', 'getMatchData_Accounts', {})
    }

    public async saveContactMatchData(datas: ILabMatchData_Contact[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'saveContactMatchData', datas, { uID })
    }

    public async saveAccountMatchData(datas: ILabMatchData_Account[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'saveAccountMatchData', datas, { uID })
    }


    public async getLabSampleDatas(): Promise<ILabSampleData[]> {
        return await this._webapi.get<ILabSampleData[]>('lab', 'GetLabSampleDatas', {})
    }

    public async saveSamples(datas: ILabSampleData[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'SaveSamples', datas, { uID })
    }

    public async getRequestedUsers(): Promise<IUser[]> {
        return await this._webapi.get<IUser[]>('lab', 'GetRequestedUsers', {})
    }

    public async downloadFileForExcelPage(pageType: number, date: string): Promise<void> {
        let getFile = await this._webapi.get<IFile>('lab', 'GetFileForExcelPage', { pageType, date });
        this._webapi.download(getFile.fileID, getFile.isTemporary)
    }

    public async getFieldCoordinators(isActive: boolean): Promise<IFieldCord_User[]> {
        return await this._webapi.get<IFieldCord_User[]>('lab', 'GetFieldCoordinators', { isActive })
    }
    public async getFieldCoordinatorApportionments(year: number, month: number): Promise<IFiledCord_ApportionmentListResult> {
        return await this._webapi.get<IFiledCord_ApportionmentListResult>('lab', 'GetFieldCoordinatorApportionments', { year, month })
    }
    public async saveFieldCoordinators(users: IFieldCord_User[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'SaveFieldCoordinators', users, { uID })
    }
    public async saveApportionments(param: IFiledCord_ApportionmentListResult, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'SaveApportionments', param, { uID })
    }
    public async getHelperMatchData_Results(): Promise<ILabHelper_ResultMatchData> {
        return await this._webapi.get<ILabHelper_ResultMatchData>('lab', 'GetHelperMatchData_Results', {})
    }
    public async saveHelperMatchDataResult(data: ILabHelper_ResultMatchData, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('lab', 'SaveHelperMatchDataResult', data, { uID })
    }


}

