import { Injectable } from '@angular/core'
import { WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IRequest, IRequestResult, IRequestList, IReqTest, ISample, IRequestListResult, ILabPropDetail, IFile, IReportType, IExtUser, ISFDCUser } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class SalesForceService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async loginSalesForce(): Promise<void> {
        window.location.replace('/api/salesForce/loginsalesforce')
        //let x = await this._webapi.get<void>('request', 'LoginSalesForce', {})
        return
    }

    public async getReport(): Promise<string> {
        return this._webapi.get<string>('salesForce', 'GetReport', {})
    }

    public async getAccountReport(): Promise<string> {
        return this._webapi.get<string>('salesForce', 'GetAccountReport', {})
    }

    public async getUsername(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this._webapi.get<string>('salesForce', 'GetUsername', {}).then((vl) => {
                if (vl && vl != '')
                    resolve(vl)
                else
                    resolve('')
            }).catch(e => reject(e))
        })
    }

    public async getReportList(): Promise<IReportType[]> {
        return this._webapi.get<IReportType[]>('salesForce', 'ReportList', {})
    }

    public async getUser(): Promise<IExtUser> {
        let vl = await this._webapi.get<ISFDCUser>('salesForce', 'GetUser', {})
        return { type: "SalesForce", email: vl.email, id: vl.id, name: vl.name }
    }

    public async setReport(reportId: string): Promise<boolean> {
        return this._webapi.get<boolean>('salesForce', 'SetReport', { reportId })
    }

    public async setAccountReport(reportId: string): Promise<boolean> {
        return this._webapi.get<boolean>('salesForce', 'SetAccountReport', { reportId })
    }

    public async loadReport(): Promise<boolean> {
        return this._webapi.get<boolean>('salesForce', 'LoadReport', { })
    }

}
