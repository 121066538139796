import { IAccount, IContact, IFile } from '.';
import { IFile_Empty } from './general';
import { IUser, IUser_Empty } from './user';

export interface IProductGroup {
    ID: number
    name: string
    products: IProduct[]
    active: boolean
    selected: boolean
}
export interface IProduct {
    ID: number
    name: string
    active: boolean
    selected: boolean
    multiplier: number
    disabled: boolean
}
export interface IReqMod_Vendor {
    code: string
    name: string
    file: IFile
    serverCode: string
    isActive: boolean
    addedBy: IUser
}

export const IReqMod_Vendor_Empty: IReqMod_Vendor = { code: '', name: '', isActive: false, serverCode: '', addedBy: IUser_Empty, file: IFile_Empty }

export interface ICurrency {
    fromCurrency: string
    toCurrency: string
    multiplier: number
}

export const ICurrency_Empty: ICurrency = { fromCurrency: '', toCurrency: '', multiplier: 0 }

export interface IList_RequestVendor {
    type: string
    typeID: number
    foreignID: number
    eventTitle: string
    startDate: string
    endDate: string
    center: string
    hcp: string
    account: string
    vendor: IReqMod_Vendor
    dateText: string
}

export const IList_RequestVendor_Empty: IList_RequestVendor = { type: '', typeID: 0, foreignID: 0, eventTitle: '', startDate: '01.01.1800', endDate: '01.01.1800', center: '', hcp: '', account: '', vendor: IReqMod_Vendor_Empty, dateText: '' }

export interface IReqType {
    ID: number
    groupName: string
    subGroupName: string
    PKU: number
    MPS: number
    CLN2: number
    HEMP: number
    ACH: number
    isActive: boolean
}

export const IReqType_Empty: IReqType = { ID: 0, groupName: '', subGroupName: '', PKU: 0, MPS: 0, CLN2: 0, HEMP: 0, ACH: 0, isActive: false }

export interface IBudget {
    ID: number
    year: number
    name: string
    total: number
}
export const IBudget_Empty: IBudget = { ID: 0, name: '', total: 0, year: 0 }


export interface IList_Budget extends IBudget {
    isActive: boolean
    used: number
    currency: string
}

export const IList_Budget_Empty: IList_Budget = { ...IBudget_Empty, isActive: false, used: 0, currency: '' }

export interface IInfusionBudget {
    year: number
    total: number
}
export const IInfusionBudget_Empty: IInfusionBudget = { total: 0, year: 0 }

export interface IList_InfusionBudget extends IInfusionBudget {
    used: number
}

export const IList_InfusionBudget_Empty: IList_InfusionBudget = { ...IInfusionBudget_Empty, used: 0 }

export interface IInfusionBudget_Info {

    unitPrice: number
    budget: number
    quantity: number
    spend: number
    spendRatio: number
}

export interface IReqModFile {
    file: IFile
    fileType: string
    rowID: number
    typeID: number
    foreignID: number
}

export const IReqModFile_Empty: IReqModFile = { file: IFile_Empty, fileType: '', rowID: 0, typeID: 0, foreignID: 0 }

export interface IReqMod_ConciliationDocument extends IReqModFile {
    canDelete: boolean
    actionTitle: string
}

export const IReqMod_ConciliationDocument_Empty: IReqMod_ConciliationDocument = { ...IReqModFile_Empty, canDelete: false, actionTitle: '' }
export interface IReqMod_HCPPersonelInformation {
    contactID: string
    birthday: string
    passportType: string
    passportNo: string
}
export interface IList_Request {
    requestID: string
    typeID: number
    typeText: string
    toWho: string
    totalBudget: number
    statusText: string
    currency: string
    vat: number
}
export interface IReqTransEventTypeResult {
    success: boolean
    newTypeID: number
    newForeignID: number
}

export interface IList_TransferOfValue {
    selected: boolean
    typeID: number
    foreignID: number
    eventTitle: string
    eventCenter: string
    eventCity: string
    eventStartTime: string
    eventEndTime: string
    typeText: string
    accommodationBudget: number
    transportationBudget: number
    hospitalityBudget: number
    otherBudget: number
    recordBudget: number
    transferBudget: number
    total: number
    currency: string
    isCompleted: boolean
    tryMultiplier: number
}
export interface IList_TransferOfValueHCP {
    ID: string
    hcp: string
    accountID: string
    account: string
    list: IList_TransferOfValue[]
}
export interface IList_TransferOfValueAccount {
    ID: string
    name: string
    list: IList_TransferOfValue[]
}
export interface IList_TransferOfValueResult {
    hcps: IList_TransferOfValueHCP[]
    accounts: IList_TransferOfValueAccount[]
    canAction: boolean
}

/////////////////////////////////////////////ACTIONS

export interface IAnswer_BudgetSelection {
    budgetID: number

    currency: string
    currencyMultiplier: number
    vat: number

    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    otherComment: string
    comment: string
}
export interface IAnswer_OtherBudgetSelection {
    costCenter: string

    budgetID: number
    cost: number
    comment: string
    currency: string
    currencyMultiplier: number
    vat: number
}
export interface IAnswer_Confirm {
    actionResult: string
    comment: string
}
export interface IAnswer_Reject {
    comment: string
}
export interface IAnswer_AddAgenda {

    files: IReqModFile[]
    currentFile: IReqModFile
    comment: string
}
export interface IAnswer_AddingDocuments {

    files: IReqModFile[]
    currentFile: IReqModFile
    comment: string
}
export interface IAnswer_EnteringThePlannedQuota {
    accounts: IScientificEventAccount[]
    comment: string
}
export interface IAnswer_MHN {
    mhnCode: string
    mhnDate: string
    screenshot: IReqModFile
    comment: string
    isNA: boolean
}
export interface IAnswer_FeedBack {
    feedBackDate: string
    screenshot: IReqModFile
    comment: string
}
export interface IAnswer_HCP_FeedBack {
    comment: string
    result: string
    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetRecord: number
    budgetHospitality: number
    budgetOther: number

    currency: string
    currencyMultiplier: number
    vat: number
}
export interface IAnswer_Event_FeedBack {
    comment: string
    result: string
    cost: number

    currency: string
    currencyMultiplier: number
    vat: number
}
export interface IAnswer_Veeva {
    comment: string
    result: string
}

export interface IAnswer_EventMoHConfirm {
    comment: string
    actionResult: string
}
export interface IAnswer_EventMHN {
    comment: string
    date: string
    code: string
    screenshot: IReqModFile
    requestList: IList_Request[]
}
export interface IAnswer_VeevaFormUpload {
    comment: string
    hcpList: IList_VeevaFormUploadHCP[]
}
export interface IList_VeevaFormUploadHCP {
    typeID: number
    foreignID: number
    contactID: string
    fullName: string
    form: IFile
}
export interface IAnswer_DueDiligenceCheckList {
    subjects: IAnswer_DueDiligenceCheckList_Helper[]
    notes: string[]

    actionBy: string
    actionByTitle: string
    actionOn: string
}
export interface IAnswer_DueDiligenceCheckList_Helper {
    subjectID: number
    subject: string
    comment: string
    completed: boolean
}

export interface IReqModAction {
    typeID: number
    foreignID: number
    step: number
    action: string
    plannedBy: string
    actionBy: string
    actionOn: string
    comment: string
    isCompleted: boolean
    actionTitle: string
    statusText: string
    screenMode: string
    errorMessage: string
    isAuto: boolean
    isTopPlanned: boolean
    canChange: boolean
    hasChange: boolean
    disabled: boolean

    confirmMessage: string
}
export interface IReqModAction_BudgetSelection extends IReqModAction {
    answer: IAnswer_BudgetSelection
}
export interface IReqModAction_OtherBudgetSelection extends IReqModAction {
    answer: IAnswer_OtherBudgetSelection
}
export interface IReqModAction_Confirm extends IReqModAction {
    answer: IAnswer_Confirm
}
export interface IReqModAction_Reject extends IReqModAction {
    answer: IAnswer_Reject
}
export interface IReqModAction_AddAgenda extends IReqModAction {
    answer: IAnswer_AddAgenda
}
export interface IReqModAction_AddingDocuments extends IReqModAction {
    answer: IAnswer_AddingDocuments
}
export interface IReqModAction_EnteringThePlannedQuota extends IReqModAction {
    answer: IAnswer_EnteringThePlannedQuota
}
export interface IReqModAction_MHN extends IReqModAction {
    answer: IAnswer_MHN
}
export interface IReqModAction_VeevaFormUpload extends IReqModAction {
    answer: IAnswer_VeevaFormUpload
}
export interface IReqModAction_HCP_FeedBack extends IReqModAction {
    answer: IAnswer_HCP_FeedBack
    attended: boolean
}
export interface IReqModAction_Event_FeedBack extends IReqModAction {
    answer: IAnswer_Event_FeedBack
}
export interface IReqModAction_Veeva extends IReqModAction {
    answer: IAnswer_Veeva
}


export interface IReqModAction_EventMoHConfirm extends IReqModAction {
    answer: IAnswer_EventMoHConfirm
}

export interface IReqModAction_EventMHN extends IReqModAction {
    answer: IAnswer_EventMHN
}
export interface IReqModAction_DueDiligenceCheckList extends IReqModAction {
    answer: IAnswer_DueDiligenceCheckList
}


export interface ISearchParam_ReqAllList {
    ID: number
    typeText: string
    eventID: number
    eventTitle: string
    forRequest: boolean
    year: number
    bYear: number
}export interface IList_Event {
    ID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    typeID: number
    typeText: string
    statusText: string
    dateSort: string
}export interface IList_EventHCP {
    ID: number
    typeID: number
    typeText: string
    eventID: number
    eventTypeID: number
    eventTypeText: string
    eventTitle: string
    eventDate: string
    eventSortDate: string
    action: string
    contact: IContact
    totalCost: number
    currency: number
    vat: string
    statusText: string
}export interface IList_EventOther {
    ID: number
    typeID: number
    typeText: string
    eventID: number
    eventTypeID: number
    eventTypeText: string
    eventTitle: string
    eventDate: string
    eventSortDate: string
    action: string
    account: IAccount
    totalCost: number
    currency: number
    vat: string
    statusText: string
    addedBy: string
}

/////////////////////////////////////////////CONGRESS

export interface ISearchParam_Congress {
    ID: number
    title: string
    forRequest: boolean
    year: number
}

export interface ISearchParam_CongressHCP {
    congressID: number
    ID: number
    year: number
}

export interface ICongress {
    ID: number
    typeID: number
    title: string
    startDate: string
    endDate: string
    lastRecordDate: string
    isAbroad: boolean
    center: string
    city: string
    willMinistryStatement: boolean
    budget: IList_Budget
    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    webSite: string
    mnhCode: string
    mnhDate: string
    agency: string
    organizer: string

    currency: string
    currencyMultiplier: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}

export const ICongress_Empty: ICongress = {
    productGroups: [],
    vendor: { addedBy: { firstname: '', lastname: '', title: '', userID: '' }, serverCode: '', code: '', name: '', file: { extension: '', fileID: '', filename: '', isTemporary: true }, isActive: false },
    currency: '', currencyMultiplier: 0,
    agency: '', organizer: '', mnhDate: '01.01.1800',
    mnhCode: '', budget: { year: 0, currency: '', ID: 0, name: '', total: 0, used: 0, isActive: false }, center: '', city: '', endDate: '01.01.1800', ID: 0, isAbroad: false, lastRecordDate: '01.01.1800', plannedBudgetAccommodationPerPerson: 0, plannedBudgetHospitalityPerPerson: 0, plannedBudgetOtherPerPerson: 0, plannedBudgetRecordPerPerson: 0, plannedBudgetTransferPerPerson: 0,
    plannedBudgetTransportationPerPerson: 0, startDate: '01.01.1800', title: '', typeID: 0, webSite: '', willMinistryStatement: true
}

export interface IDetail_Congress {
    congress: ICongress
    requests: IList_CongressRequest[]
    actions: IReqModAction[]
    files: IReqModFile[]
    transferOtherEventType: boolean
}

export const IDetail_Congress_Empty: IDetail_Congress = { congress: ICongress_Empty, requests: [], actions: [], files: [], transferOtherEventType: false }

export interface IDetail_CongressHCP {
    congress: ICongress
    hcp: ICongressHCP
    actions: IReqModAction[]
    //budget: IBudget
    addedBy: string
    addedOn: string
}


export interface IResult_CongressList {
    congresses: IList_Congress[]
}

export interface IList_Congress {
    ID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    hasRequest: boolean
    typeID: number
    statusText: string
}

export interface IList_CongressHCP {
    ID: number
    congressID: number
    typeID: number
    action: string
    contact: IContact
    participantType: string
    requestedAccommodation: number
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    TransferComments: string
    budget: IBudget

    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetRecord: number
    budgetHospitality: number
    budgetOther: number
    comments: string
}

export interface ICongressHCP {
    ID: number
    congressID: number
    typeID: number
    action: string
    contact: IContact
    participantType: string
    requestedAccommodation: number
    accommodationScientificProgram: boolean
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationScientificProgram: boolean
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    transferComments: string
    budget: IList_Budget

    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetRecord: number
    budgetHospitality: number
    budgetOther: number
    comments: string

    willValueTransfer: boolean
    birthday: string
    passportType: string
    passportNo: string
    institutionType: string

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface ICongressOther {
    ID: number
    congressID: number
    typeID: number
    account: IAccount
    budget: IList_Budget
    action: string
    type: string
    comments: string
    willMinistryStatement: boolean
    cost: number

    willValueTransfer: boolean

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface IList_CongressRequest extends IList_Request {
    //requestID: string
    //typeID: number
    //typeText: string
    //toWho: string
    //totalBudget: number
    //statusText: string
    //currency: string
}

export interface IDetail_CongressOther {
    congress: ICongress
    other: ICongressOther
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}

/////////////////////////////////////////////SCIENTIFIC EVENTS
export interface IScientificEvent {
    ID: number
    typeID: number
    isAbroad: boolean
    title: string
    startDate: string
    startTime: string
    endDate: string
    endTime: string
    lastRecordDate: string
    center: string
    city: string
    willMinistryStatement: boolean
    budget: IList_Budget
    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    agency: string
    organizer: string

    currency: string
    currencyMultiplier: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}

export const IScientificEvent_Empty: IScientificEvent = {
    vendor: IReqMod_Vendor_Empty,
    currency: '', currencyMultiplier: 0,
    agency: '', organizer: '', budget: IList_Budget_Empty, center: '', city: '', endDate: '01.01.1800', endTime: '00:00', ID: 0, isAbroad: false, lastRecordDate: '01.01.1800', plannedBudgetAccommodationPerPerson: 0, plannedBudgetHospitalityPerPerson: 0, plannedBudgetOtherPerPerson: 0, plannedBudgetRecordPerPerson: 0, plannedBudgetTransferPerPerson: 0, plannedBudgetTransportationPerPerson: 0, startDate: '01.01.1800', startTime: '00:00', title: '', typeID: 0, willMinistryStatement: true,productGroups : []
}

export interface IScientificEventHCP {
    ID: number
    typeID: number
    scientificEventID: number
    action: string
    contact: IContact

    participantType: string
    requestedAccommodation: number
    accommodationScientificProgram: boolean
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationScientificProgram: boolean
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    transferComments: string


    budget: IList_Budget
    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetHospitality: number
    budgetOther: number
    comments: string

    willValueTransfer: boolean
    birthday: string
    passportType: string
    passportNo: string
    institutionType: string

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface IScientificEventAccount {
    scientificEventID: number
    accountID: string
    accountName: string
    specialty: string
    count: number
    isDeleted: boolean
    addedBy: string
}

export interface IList_ScientificEventRequest extends IList_Request {
    //typeID: number
    //requestID: string
    //typeText: string
    //toWho: string
    //totalBudget: number
    //statusText: string
    //currency: string
}

export interface ISearchParam_ScientificEvent {
    ID: number
    title: string
    startDate: string
    endDate: string
    forRequest: boolean
    year: number
}

export interface IResult_ScientificEventList {
    scientificEvents: IList_ScientificEvent[]
}

export interface IList_ScientificEvent {
    ID: number
    typeID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    hasRequest: boolean
    statusText: string
}

export interface IDetail_ScientificEvent {
    scientificEvent: IScientificEvent
    actions: IReqModAction[]
    files: IScientificEventFiles[]
    accounts: IScientificEventAccount[]
    requests: IList_ScientificEventRequest[]
    transferOtherEventType: boolean
}

export const IDetail_ScientificEvent_Empty: IDetail_ScientificEvent = {
    transferOtherEventType: false,
    requests: [], accounts: [], actions: [], files: [], scientificEvent: IScientificEvent_Empty
}

export interface IScientificEventFiles {
    scientificEventID: number
    fileID: string
    fileName: string
    fileType: string
}

export interface ISearchParam_ScientificEventHCP {
    ID: number
    scientificEventID: number
    year: number
}

export interface IScientificEventOther {
    ID: number
    scientificEventID: number
    typeID: number
    account: IAccount
    budget: IList_Budget
    action: string
    type: string
    comments: string
    willMinistryStatement: boolean
    cost: number
    currency: string
    currencyMultiplier: number
    vat: number
    willValueTransfer: boolean
    vendor: IReqMod_Vendor
}

export interface IList_ScientificEventHCP {
    ID: number
    contact: string
    contactAccount: string
    addedBy: string
    addedOn: string
}

export interface IDetail_ScientificEventHCP {
    scientificEvent: IScientificEvent
    hcp: IScientificEventHCP
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}

export interface IDetail_ScientificEventOther {
    scientificEvent: IScientificEvent
    other: IScientificEventOther
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}

/////////////////////////////////////////////ADVISORYBOARD

export interface IAdvisoryBoard {
    ID: number
    typeID: number
    title: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    lastRecordDate: string
    center: string
    city: string
    budgetType: number
    budget: IList_Budget
    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    agency: string
    organizer: string

    currency: string
    currencyMultiplier: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}

export const IAdvisoryBoard_Empty: IAdvisoryBoard = {
    vendor: { addedBy: { firstname: '', lastname: '', title: '', userID: '' }, serverCode: '', code: '', name: '', file: { extension: '', fileID: '', filename: '', isTemporary: true }, isActive: false },
    currency: '', currencyMultiplier: 0,
    budgetType: 1,
    agency: '', organizer: '', budget: { year: 0, currency: '', ID: 0, name: '', total: 0, used: 0, isActive: false }, center: '', city: '', endDate: '01.01.1800', endTime: '00:00', ID: 0, lastRecordDate: '01.01.1800', plannedBudgetAccommodationPerPerson: 0, plannedBudgetHospitalityPerPerson: 0, plannedBudgetOtherPerPerson: 0, plannedBudgetRecordPerPerson: 0, plannedBudgetTransferPerPerson: 0, plannedBudgetTransportationPerPerson: 0, startDate: '01.01.1800', startTime: '00:00', title: '', typeID: 0,productGroups:[]
}

export interface ISearchParam_AdvisoryBoard {
    ID: number
    title: string
    startDate: string
    endDate: string
    forRequest: boolean
    year: number
}

export interface IAdvisoryBoardHCP {
    ID: number
    typeID: number
    advisoryBoardID: number
    action: string
    contact: IContact

    contactType: string
    participantType: string
    requestedAccommodation: number
    accommodationScientificProgram: boolean
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationScientificProgram: boolean
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    transferComments: string


    budget: IList_Budget
    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetHospitality: number
    budgetOther: number
    comments: string

    willValueTransfer: boolean
    birthday: string
    passportType: string
    passportNo: string
    institutionType: string

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface IResult_AdvisoryBoardList {
    advisoryBoards: IList_AdvisoryBoard[]
}

export interface IList_AdvisoryBoard {
    ID: number
    typeID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    hasRequest: boolean
    statusText: string
}

export interface IDetail_AdvisoryBoard {
    advisoryBoard: IAdvisoryBoard
    actions: IReqModAction[]
    files: IAdvisoryBoardFiles[]
    requests: IList_AdvisoryBoardRequest[]
}

export const IDetail_AdvisoryBoard_Empty: IDetail_AdvisoryBoard = {
    requests: [],
    files: [], actions: [], advisoryBoard: IAdvisoryBoard_Empty
}

export interface IAdvisoryBoardFiles {
    advisoryBoardID: number
    fileID: string
    fileName: string
    fileType: string
}

export interface IAdvisoryBoardAccount {
    advisoryBoardID: number
    accountID: string
    accountName: string
    specialty: string
    count: number
    isDeleted: boolean
    addedBy: string
}

export interface IList_AdvisoryBoardRequest extends IList_Request {
    //typeID: number
    //requestID: string
    //typeText: string
    //toWho: string
    //totalBudget: number
    //statusText: string
    //currency: string
}

export interface ISearchParam_AdvisoryBoardHCP {
    ID: number
    AdvisoryBoardID: number
    year: number
}

export interface IAdvisoryBoardOther {
    ID: number
    advisoryBoardID: number
    typeID: number
    account: IAccount
    budget: IList_Budget
    action: string
    type: string
    comments: string
    willMinistryStatement: boolean
    cost: number
    currency: string
    currencyMultiplier: number
    vat: number
    willValueTransfer: boolean
    vendor: IReqMod_Vendor
}

export interface IList_AdvisoryBoardHCP {
    ID: number
    contact: string
    contactAccount: string
    addedBy: string
    addedOn: string
}

export interface IDetail_AdvisoryBoardHCP {
    advisoryBoard: IAdvisoryBoard
    hcp: IAdvisoryBoardHCP
    actions: IReqModAction[]
    //public IList_Budget budget 
    addedBy: string
    addedOn: string
}

export interface IDetail_AdvisoryBoardOther {
    advisoryBoard: IAdvisoryBoard
    other: IAdvisoryBoardOther
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}


/////////////////////////////////////////////PRODUCT PROMOTION MEETING
export interface IProductPromotionMeeting {
    ID: number
    typeID: number
    isAbroad: boolean
    title: string
    startDate: string
    startTime: string
    endDate: string
    endTime: string
    lastRecordDate: string
    center: string
    city: string
    willMinistryStatement: boolean
    budget: IList_Budget
    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    agency: string
    organizer: string

    currency: string
    currencyMultiplier: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}

export interface IProductPromotionMeetingHCP {
    ID: number
    typeID: number
    productPromotionMeetingID: number
    action: string
    contact: IContact

    participantType: string
    requestedAccommodation: number
    accommodationScientificProgram: boolean
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationScientificProgram: boolean
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    transferComments: string
    budget: IList_Budget
    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetHospitality: number
    budgetOther: number
    comments: string

    willValueTransfer: boolean
    birthday: string
    passportType: string
    passportNo: string
    institutionType: string

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface IProductPromotionMeetingAccount {
    productPromotionMeetingID: number
    accountID: string
    accountName: string
    specialty: string
    count: number
    isDeleted: boolean
    addedBy: string
}

export interface IList_ProductPromotionMeetingRequest extends IList_Request {
    //typeID: number
    //requestID: string
    //typeText: string
    //toWho: string
    //totalBudget: number
    //currency: string
    //statusText: string
}

export interface ISearchParam_ProductPromotionMeeting {
    ID: number
    title: string
    startDate: string
    endDate: string
    forRequest: boolean
    year: number
}

export interface IResult_ProductPromotionMeetingList {
    productPromotionMeetings: IList_ProductPromotionMeeting[]
}

export interface IList_ProductPromotionMeeting {
    ID: number
    typeID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    hasRequest: boolean
    statusText: string
}

export interface IDetail_ProductPromotionMeeting {
    productPromotionMeeting: IProductPromotionMeeting
    actions: IReqModAction[]
    files: IProductPromotionMeetingFiles[]
    accounts: IProductPromotionMeetingAccount[]
    requests: IList_ProductPromotionMeetingRequest[]
}

export interface IProductPromotionMeetingFiles {
    productPromotionMeetingID: number
    fileID: string
    fileName: string
    fileType: string
}

export interface ISearchParam_ProductPromotionMeetingHCP {
    ID: number
    productPromotionMeetingID: number
    year: number
}

export interface ISearchParam_ProductPromotionMeetingOther {
    ID: number
    productPromotionMeetingID: number
    year: number
}

export interface IProductPromotionMeetingOther {
    ID: number
    productPromotionMeetingID: number
    typeID: number
    account: IAccount
    budget: IList_Budget
    action: string
    type: string
    comments: string
    willMinistryStatement: boolean
    cost: number
    currency: string
    currencyMultiplier: number
    vat: number
    willValueTransfer: boolean
    vendor: IReqMod_Vendor
}

export interface IList_ProductPromotionMeetingHCP {
    ID: number
    contact: string
    contactAccount: string
    addedBy: string
    addedOn: string
}

export interface IDetail_ProductPromotionMeetingHCP {
    productPromotionMeeting: IProductPromotionMeeting
    hcp: IProductPromotionMeetingHCP
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}

export interface IDetail_ProductPromotionMeetingOther {
    productPromotionMeeting: IProductPromotionMeeting
    other: IProductPromotionMeetingOther
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}


/////////////////////////////////////////////SCIENTIFIC SUPPORT
export interface IScientificSupport_Other {
    ID: number
    typeID: number
    account: IAccount
    action: string
    budgetID: number
    totalBudget: number
    isMonetary: boolean
    isDifferentSupplier: boolean
    supplier: string
    comments: string
    willMinistryStatement: boolean
    addedBy: string
    addedOn: string
    MHNCode: string
    MHNDate: string
    files: IReqModFile[]
    currentFile: IReqModFile
    actions: IReqModAction[]
    willValueTransfer: boolean
    statusText: string
    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}
/////////////////////////////////////////////SCIENTIFIC SUPPORT_HCP
export interface IScientificHCPSupport_Other {
    ID: number
    typeID: number
    contact: IContact
    action: string
    budgetID: number
    totalBudget: number
    isMonetary: boolean
    isDifferentSupplier: boolean
    supplier: string
    comments: string
    willMinistryStatement: boolean
    addedBy: string
    addedOn: string
    MHNCode: string
    MHNDate: string
    files: IReqModFile[]
    currentFile: IReqModFile
    actions: IReqModAction[]
    willValueTransfer: boolean
    statusText: string
    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}
/////////////////////////////////////////////OTHER MODULE
export interface IOtherList extends IList_Request {
    //typeID: number
    eventName: string
    //foreignID: number
    //typeText: string
    text: string
    date: string
    cost: string
    addedBy: string
    addedOn: string
    //statusText: string
    //currency: string
}

export interface ISpeakerAgreement {
    ID: number
    typeID: number
    contact: IContact
    budgetID: number
    totalBudget: number
    eventTitle: string
    eventStartTime: string
    eventEndTime: string
    projectScopeAIM: string
    comments: string
    willMinistryStatement: boolean
    addedBy: string
    addedOn: string
    MHNCode: string
    MHNDate: string
    files: IReqModFile[]
    actions: IReqModAction[]
    willValueTransfer: boolean
    statusText: string
    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}
export interface IList_SpeakerAgreement extends IList_Request {
    foreignID: number
    eventTitle: string
    eventDate: string
    eventStartTime: string
    eventEndTime: string
    addedBy: string
    addedOn: string
}
/////////////////////////////////////////////KnowledgeSharingForum

export interface IKnowledgeSharingForum {
    ID: number
    typeID: number
    title: string
    startDate: string
    startTime: string
    endDate: string
    endTime: string
    lastRecordDate: string
    isAbroad: boolean
    center: string
    city: string
    willMinistryStatement: boolean
    budget: IList_Budget
    plannedBudgetAccommodationPerPerson: number
    plannedBudgetTransportationPerPerson: number
    plannedBudgetOtherPerPerson: number
    plannedBudgetRecordPerPerson: number
    plannedBudgetHospitalityPerPerson: number
    plannedBudgetTransferPerPerson: number
    webSite: string
    mnhCode: string
    mnhDate: string
    agency: string
    organizer: string

    currency: string
    currencyMultiplier: number
    vendor: IReqMod_Vendor
    productGroups: IProductGroup[]
}

export const IKnowledgeSharingForum_Empty: IKnowledgeSharingForum = {
    vendor: IReqMod_Vendor_Empty,
    currency: '', currencyMultiplier: 0,
    mnhCode: '', mnhDate: '01.01.1800', webSite: '',
    agency: '', organizer: '', budget: IList_Budget_Empty, center: '', city: '', endDate: '01.01.1800', endTime: '00:00', ID: 0, isAbroad: false, lastRecordDate: '01.01.1800', plannedBudgetAccommodationPerPerson: 0, plannedBudgetHospitalityPerPerson: 0, plannedBudgetOtherPerPerson: 0, plannedBudgetRecordPerPerson: 0, plannedBudgetTransferPerPerson: 0, plannedBudgetTransportationPerPerson: 0, startDate: '01.01.1800', startTime: '00:00', title: '', typeID: 0, willMinistryStatement: true,productGroups:[]
}


export interface ISearchParam_KnowledgeSharingForum {
    ID: number
    title: string
    forRequest: boolean
    year: number
}

export interface ISearchParam_KnowledgeSharingForumHCP {
    knowledgeSharingForumID: number
    ID: number
}

export interface IDetail_KnowledgeSharingForum {
    knowledgeSharingForum: IKnowledgeSharingForum
    requests: IList_KnowledgeSharingForumRequest[]
    actions: IReqModAction[]
    files: IReqModFile[]
}

export const IDetail_KnowledgeSharingForum_Empty: IDetail_KnowledgeSharingForum = {
    requests: [], actions: [], files: [], knowledgeSharingForum: IKnowledgeSharingForum_Empty
}

export interface IDetail_KnowledgeSharingForumHCP {
    knowledgeSharingForum: IKnowledgeSharingForum
    hcp: IKnowledgeSharingForumHCP
    actions: IReqModAction[]
    //budget: IBudget
    addedBy: string
    addedOn: string
}

export interface IResult_KnowledgeSharingForumList {
    knowledgeSharingForums: IList_KnowledgeSharingForum[]
}

export interface IList_KnowledgeSharingForum {
    ID: number
    title: string
    date: string
    location: string
    addedBy: string
    addedOn: string
    hasRequest: boolean
    typeID: number
    statusText: string
}

export interface IList_KnowledgeSharingForumHCP {
    ID: number
    knowledgeSharingForumID: number
    typeID: number
    action: string
    contact: IContact
    participantType: string
    requestedAccommodation: number
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    TransferComments: string
    budget: IBudget

    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetRecord: number
    budgetHospitality: number
    budgetOther: number
    comments: string
}

export interface IKnowledgeSharingForumHCP {
    ID: number
    knowledgeSharingForumID: number
    typeID: number
    action: string
    contact: IContact
    participantType: string
    requestedAccommodation: number
    accommodationScientificProgram: boolean
    accommodationStartTime: string
    accommodationEndTime: string
    accommodationComments: string

    requestedTransportation: number
    transportationScientificProgram: boolean
    transportationStartTime: string
    transportationEndTime: string

    transportationType: string
    transportationFrom: string
    transportationTo: string
    transportationComments: string

    requestedTransfer: number
    transferComments: string
    budget: IList_Budget

    budgetAccommodation: number
    budgetTransportation: number
    budgetTransfer: number
    budgetRecord: number
    budgetHospitality: number
    budgetOther: number
    comments: string

    willValueTransfer: boolean
    birthday: string
    passportType: string
    passportNo: string
    institutionType: string

    currency: string
    currencyMultiplier: number
    vat: number
    vendor: IReqMod_Vendor
}

export interface IKnowledgeSharingForumOther {
    ID: number
    knowledgeSharingForumID: number
    typeID: number
    account: IAccount
    budget: IList_Budget
    action: string
    type: string
    comments: string
    willMinistryStatement: boolean
    cost: number
    currency: string
    currencyMultiplier: number
    willValueTransfer: boolean
    vat: number
    vendor: IReqMod_Vendor
}

export interface IList_KnowledgeSharingForumRequest extends IList_Request {
    //requestID: string
    //typeID: number
    //typeText: string
    //toWho: string
    //totalBudget: number
    //statusText: string
    //currency: string
}

export interface IDetail_KnowledgeSharingForumOther {
    knowledgeSharingForum: IKnowledgeSharingForum
    other: IKnowledgeSharingForumOther
    actions: IReqModAction[]
    addedBy: string
    addedOn: string
}

export interface IDetail_Budget {
    year: number
    type: string
    typeID: number
    foreignID: number
    eventID: number
    eventTitle: string
    eventStartTime: string
    eventEndTime: string
    HCP_ContactID: string
    HCP: string
    HCP_AccountID: string
    HCP_Account: string
    institution_AccountID: string
    institution_Account: string
    accommodation: number
    hospitality: number
    other: number
    record: number
    transfer: number
    transportation: number
    budget_total: number
    budget_currency: string
    budget_currencyMultiplier: number
    total: number
    budgetID: number
    vat: number
}



export interface IPendingAction {
    typeImportant: number
    important: number
    typeId: number
    id: number
    eventTitle: string
    hcpName: string
    accountName: string
    action: string
    plannedBy: string
    plannedTime: string
    isCompleted: boolean
    typeGroup: string
    typeSubGroup: string
}


export interface IPendingActionList {
    pendingActions: IPendingAction[]
}

export interface IPendingConsent {
    xType: string
    ID: string
    fullName: string
}

export interface IOpenEvent {
    typeId: number
    title: string
    center: string
    city: string
    startTime: string
    endTime: string
    typeSubGroup: string
}

export interface IOpenEventList {
    openEvents: IOpenEvent[]
}


export interface IReqModFileType {
    actionTitle: string
    fileType: string
    tempFile: IFile
    isActive: boolean
    isNew: boolean
}