import { Injectable } from '@angular/core'
import { WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IFile, IContact, IContactTest, IUser } from '@shared/models';
import { ITestAction, ITestDBS, ISearchParam_DBS, IListResult_DBS, ITestFilter, IListResult_Filter, ISearchParam_Filter, ITestKuvan, IListResult_Kuvan, ISearchParam_Kuvan, IDetail_Kuvan, IDetail_DBS, IDetail_Filter, ITestPropDetail, ITestVOX, IListResult_VOX, ISearchParam_VOX, IDetail_VOX, IStock, IDBSStock, IVOXStock, IKVNStock } from '@shared/models/test';

@Injectable({
    providedIn: 'root'
})
export class TestService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async getRequestedUsers(typeID: number): Promise<IUser[]> {
        return await this._webapi.get<IUser[]>('test', 'GetRequestedUsers', { typeID })
    }

    public async getDBSStock(typeID: number): Promise<IStock> {
        return await this._webapi.get<IStock>('test', 'GetDBSStock', { typeID })
    }

    public async getVOXStock(): Promise<IStock> {
        return await this._webapi.get<IStock>('test', 'GetVOXStock', { })
    }

    public async getKVNStock(): Promise<IStock> {
        return await this._webapi.get<IStock>('test', 'GetKVNStock', {})
    }
    public async saveDBSStock(value: IDBSStock, userID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'SaveDBSStock', value, { userID })
    }

    public async saveVOXStock(value: IVOXStock, userID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'SaveVOXStock', value, { userID })
    }

    public async saveKVNStock(value: IKVNStock, userID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'SaveKVNStock', value, { userID })
    }

    public async updateActionPlannedBy(action: ITestAction, newPlannedByUserID: string, userID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'UpdateActionPlannedBy', action, { newPlannedByUserID, userID })
    }
    public async test_DBS(param: ITestDBS, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'Test_DBS', param, { uID })
    }
    public async getList_DBS(param: ISearchParam_DBS, uID: string): Promise<IListResult_DBS> {
        return await this._webapi.post<IListResult_DBS>('test', 'GetList_DBS', param, { uID })
    }
    public async getDetail_DBS(param: ISearchParam_DBS, uID: string): Promise<IDetail_DBS> {
        return await this._webapi.post<IDetail_DBS>('test', 'getDetail_DBS', param, { uID })
    }

    public async test_VOX(param: ITestVOX, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'Test_VOX', param, { uID })
    }
    public async getList_VOX(param: ISearchParam_VOX, uID: string): Promise<IListResult_VOX> {
        return await this._webapi.post<IListResult_VOX>('test', 'GetList_VOX', param, { uID })
    }
    public async getDetail_VOX(param: ISearchParam_VOX, uID: string): Promise<IDetail_VOX> {
        return await this._webapi.post<IDetail_VOX>('test', 'getDetail_VOX', param, { uID })
    }

    public async test_Filter(param: ITestFilter, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'test_Filter', param, { uID })
    }
    public async getList_Filter(param: ISearchParam_Filter, uID: string): Promise<IListResult_Filter> {
        return await this._webapi.post<IListResult_Filter>('test', 'GetList_Filter', param, { uID })
    }
    public async getDetail_Filter(param: ISearchParam_Filter, uID: string): Promise<IDetail_Filter> {
        return await this._webapi.post<IDetail_Filter>('test', 'getDetail_Filter', param, { uID })
    }

    public async test_Kuvan(param: ITestKuvan, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'test_Kuvan', param, { uID })
    }
    public async getList_Kuvan(param: ISearchParam_Kuvan, uID: string): Promise<IListResult_Kuvan> {
        return await this._webapi.post<IListResult_Kuvan>('test', 'GetList_Kuvan', param, { uID })
    }
    public async getDetail_Kuvan(param: ISearchParam_Kuvan, uID: string): Promise<IDetail_Kuvan> {
        return await this._webapi.post<IDetail_Kuvan>('test', 'getDetail_Kuvan', param, { uID })
    }

    public async action(param: ITestAction[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('test', 'action', param, { uID })
    }

    public async searchContact(text: string, uID: string): Promise<IContact[]> {
        return await this._webapi.get<IContact[]>('test', 'SearchContact', { text, uID })
    }

    public async getContactTests(contactID: string): Promise<IContactTest[]> {
        return await this._webapi.get<IContactTest[]>('test', 'GetContactTests', { contactID })
    }

    public async getTestExcel(typeID: number): Promise<void> {
        let file: IFile = await this._webapi.get<IFile>('test', 'GetTestExcel', { typeID })
        await this._webapi.download(file.fileID, file.isTemporary)
    }
    public async getDBSSubTypes(): Promise<ITestPropDetail[]> {
        return await this._webapi.get<ITestPropDetail[]>('test', 'GetDBSSubTypes', {})
    }
}

