import { Injectable } from '@angular/core'
import { WebApiService } from './webapi.service'
import { StorageService } from './storage.service'
import { SettingsService } from './settings.service'
import { IContactTest, IG2IRequest, IG2IRequestListResult, IG2IRequestResult, IG2ISample, IG2ISampleData, IUser } from '@shared/models';

@Injectable({
    providedIn: 'root'
})
export class Gene2InfoService {
    constructor(private _webapi: WebApiService, private _storage: StorageService, private _settings: SettingsService) { }

    public async addNewRequest(request: IG2IRequest, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('gene2info', 'addNewRequest', request, { uID })
    }
    public async changeRequestDetail(request: IG2IRequest, isCancel: boolean, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('gene2info', 'ChangeRequestDetail', request, { isCancel, uID })
    }
    public async getSamples(): Promise<IG2ISample[]> {
        return await this._webapi.get<IG2ISample[]>('gene2info', 'getSamples', {})
    }
    public async getRequest(requestID: number, uID: string): Promise<IG2IRequestResult> {
        return await this._webapi.get<IG2IRequestResult>('gene2info', 'GetRequest', { requestID, uID })
    }

    public async getList(userID: string, tip: number): Promise<IG2IRequestListResult> {
        return await this._webapi.get<IG2IRequestListResult>('gene2info', 'GetList', {  userID, tip})
    }

    public async getContactLastRequest(contactID: string, reqType: boolean): Promise<IG2IRequest> {
        return await this._webapi.get<IG2IRequest>('gene2info', 'GetContactLastRequest', { contactID, reqType })
    }

    public async getContactTests(contactID: string): Promise<IContactTest[]> {
        return await this._webapi.get<IContactTest[]>('gene2info', 'GetContactTests', { contactID })
    }

    public async getLabSampleDatas(): Promise<IG2ISampleData[]> {
        return await this._webapi.get<IG2ISampleData[]>('gene2info', 'GetLabSampleDatas', {})
    }

    public async saveSamples(datas: IG2ISampleData[], uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('gene2info', 'SaveSamples', datas, { uID })
    }

    public async getRequestedUsers(): Promise<IUser[]> {
        return await this._webapi.get<IUser[]>('gene2info', 'GetRequestedUsers', {})
    }

}

