import { Component, OnDestroy, NgZone, HostBinding, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
//import { SignalRService, UserService, StorageService } from '@shared/services'
//import { IUser } from '@shared/models'

//import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {

    @HostBinding('class.loading') loading = false;

    constructor(public _router: Router) {
        //this._router.events.subscribe(event => {
        //    if (event instanceof NavigationEnd) {
        //        ga('set', 'page', event.urlAfterRedirects);
        //        ga('send', 'pageview');
        //    }
        //});
    }



    //title = 'app';

    //private _subcription: Subscription
    //private _subs: Subscription
    //public _avail: boolean
    //public mess: { hubName: string, clientFunction: string, parameters: string }[] = []

    //constructor(private signal: SignalRService, private zone: NgZone, private _user: UserService, private _store: StorageService) {
    //    this._subcription = signal.messageReceived$.subscribe((vl) => {
    //        console.log(JSON.stringify(vl.parameters))
    //        //zone.run(() => {
    //            this.mess.push({ hubName: vl.hubName, clientFunction: vl.clientFunction, parameters: JSON.stringify(vl.parameters) })
    //        //})
    //    })
    //    this._subs = signal.connectionStateChanged$.subscribe((vl) => {
    //        this._avail = vl
    //    })

    //    signal.track('testHub', 'addNewMessageToPage')
    //    signal.start()
    //        .then(() => {
    //        })
    //}

    //send() {
    //    this.signal.invoke('testHub', 'send', 'user', 'message')
    //}

    //ngOnDestroy() {
    //    if (this._subs) this._subs.unsubscribe()
    //    if (this._subcription) this._subcription.unsubscribe()
    //}

    //login() {
    //    this._user.login('atsualp', '1234')
    //        .then((vl) => {
    //            alert(JSON.stringify(vl))
    //        })
    //}

    //download() {
    //    //this._user.download('file');
    //}
}
