import { Injectable, isDevMode } from '@angular/core'

export enum RunningModes {
    Production,
    Development,
    Test
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    constructor() { }

    public get Help_EmailAddress(): string {
        return 'OAOZ (Ozkan Ozer) <oaoz@novonordisk.com'
    }

    public get Help_EmailSubject(): string {
        return 'One Portal Problem Bildirimi'
    }

    public get isDevelopment(): RunningModes {
        return RunningModes.Development
        //return isDevMode()
    }


}