import { Injectable } from '@angular/core';
import { WebApiService } from './webapi.service'
import { SettingsService } from './settings.service'
import { IUpdateMultipliersPageProduct, IWarehouse } from '../models/marketReport';


@Injectable({
    providedIn: 'root'
})
export class MarketReportService {
    constructor(private _webapi: WebApiService, private _settings: SettingsService) { }

    public async importIMS(fileID: string, uID: string): Promise<boolean> {
        return await this._webapi.get<boolean>('marketreport', 'ImportIMS', { fileID, uID })
    }
    public async importGen(fileID: string, uID: string): Promise<boolean> {
        return await this._webapi.get<boolean>('marketreport', 'ImportGen', { fileID, uID })
    }


    public async getWarehouses(uID: string): Promise<IWarehouse[]> {
        return await this._webapi.get<IWarehouse[]>('marketreport', 'warehouses', { uID })
    }

    public async addUpdateWarehouseDistribution(warehouseCode: string, city: string, impactRate: number, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('marketreport', 'addupdatewarehousedistribution', {}, { warehouseCode, city, impactRate, uID })

    }

    public async deleteWarehouseDistribution(warehouseCode: string, city: string, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('marketreport', 'deletewarehousedistribution', {}, { warehouseCode, city, uID })
    }

    public async updateWarehouseGroup(warehouseCode: string, group: string, uID: string): Promise<boolean> {
        return await this._webapi.post<boolean>('marketreport', 'updatewarehousegroup', {}, { warehouseCode, group, uID })
    }


    public async saveProductMultipliers(param: IUpdateMultipliersPageProduct[]): Promise<boolean> {
        return await this._webapi.post<boolean>('marketreport', 'saveProductMultipliers', param, {})
    }
    public async getProductMultipliers(): Promise<IUpdateMultipliersPageProduct[]> {
        return await this._webapi.get<IUpdateMultipliersPageProduct[]>('marketreport', 'getProductMultipliers', {})
    }

}