
export interface IMessageResult<T> {
    hasError: boolean
    message: string
    data: T
}

export interface IFile {
    fileID: string
    filename: string
    extension: string
    isTemporary: boolean
}

export const IFile_Empty: IFile = { fileID: '', extension: '', filename: '', isTemporary: true }

export interface IMenu {
    title: string;
    routerUrl?: string;
    externalUrl?: string;
    iconClass?: string;
    iconCode?: string;
    count?: number;
    children?: IMenu[];
    opened?: boolean;
    selected?: boolean;
}


export interface IExtUser {
    id: string
    name: string
    email: string
    type: 'SalesForce' | 'Microsoft'
}


export interface ITranslation {
    translation: string
    language: string
    translated: boolean
}
