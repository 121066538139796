import { Injectable } from '@angular/core'
import { retry } from 'rxjs/operators';

declare var swal: any

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor() { }

    public async alert(message: string): Promise<void> {
        return this.warning(message);
        //return new Promise<void>((resolve, reject) => {
        //    swal({ title: message })
        //        .then((result) => {
        //            resolve()
        //        }).catch((e) => {
        //            reject()
        //        })
        //})
    }

    public async info(title: string, message: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal({ type: 'info', title: title, text: message })
                .then((result) => {
                    resolve()
                }).catch((e) => {
                    reject()
                })
        })
    }
    public async success(title: string, message: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal({ type: 'info', title: title, text: message })
                .then((result) => {
                    resolve()
                }).catch((e) => {
                    reject()
                })
        })
    }

    public async warning(message: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal({ type: 'warning', title: 'Oops...', text: message })
                .then((result) => {
                    resolve()
                }).catch((e) => {
                    reject()
                })
        })

    }

    public async error(message: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal({ type: 'error', title: 'Oops...', text: message })
                .then((result) => {
                    resolve()
                }).catch((e) => {
                    reject()
                })
        })

    }

    public async confirm(question: string, message: string, confirmText: string, cancelText: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal({
                type: 'question', title: question, text: message,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: confirmText,
                cancelButtonText: cancelText,
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    resolve();
                } else {
                    reject();
                }
            })
        })
    }


}